.header-text {
    font-size: 70px;
}
@include media-breakpoint-up(md) {
    .header-text {
        font-size: 120px;
    }
}

.clickable:hover {
    cursor: pointer;
}

#book-ui-div {
    width: 340px;
    padding: 20px;
    margin: auto;
}
#article-ui-div {
    width: 340px;
    margin: auto;
}

#merchandise-ui-div {
    width: 300px;
    height: 460px;
    margin: auto;
    border: 0 0 2px 0 !important;
}

#merchandise-ui-img-container {
    width: auto;
    height: 270px;
}

#merchandise-ui-img {
    overflow-y: hidden;
}

#merchandise-ui-description {
    height: 50px;
}

#blog-ui-div {
    width: 300px;
    height: 300px;
    padding-top: 30px;
    margin: auto;
}
#blog-ui-button {
    filter: invert(97%) sepia(4%) saturate(0%) hue-rotate(35deg)
        brightness(117%) contrast(100%);
}
.svg-white {
    filter: invert(97%) sepia(4%) saturate(0%) hue-rotate(35deg)
        brightness(117%) contrast(100%);
}
.footer-social-logos {
    display: inline-block !important;
    margin-left: 8px;
    width: 28px;
    margin-right: 8px;
    margin-bottom: 12px;
}

h4 {
    font-size: 20px;
}

.logo-text {
    font-size: 37px;
}

#make-sticky {
    position: sticky;
    position: -webkit-sticky;
}

.link {
    text-decoration: underline;
}
